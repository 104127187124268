export default defineNuxtRouteMiddleware((to, from) => {
    const otpStore = useOtpStore(),
        authStore = useAuthStore(),
        registerStore = useRegisterStore();

    // Set current registration step
    registerStore.currentStep =
        registerStore.steps.indexOf(to.name.split('-').slice(1).join('-')) + 1;

    toggleOnboardingSwipeBack();

    // User is already authorized and has completed registration, no ned to check registration.
    if (authStore.loggedIn) {
        return;
    }

    // Otp is not verified, redirect to code page
    if (!otpStore.verified) {
        return useRouter().push({ name: 'code' });
    }
});
